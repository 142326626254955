import { useEffect, useState } from 'react';

function FormattedDate({ date, locale, timeZone, showTime = true }) {
    const [formattedDate, setFormattedDate] = useState('');

    useEffect(() => {
        try {
            const dateObj = new Date(date);
            const options = {
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
                hour: showTime ? 'numeric' : undefined,
                minute: showTime ? 'numeric' : undefined,
                timeZone: timeZone || 'Europe/Copenhagen'
            };

            setFormattedDate(
                dateObj.toLocaleString(locale || 'en-US', options)
            );
        } catch (error) {
            setFormattedDate('Invalid Date');
        }
    }, [date, locale, timeZone, showTime]);

    // Return empty div for initial server render
    if (!formattedDate) {
        return <span>-</span>;
    }

    return <span>{formattedDate}</span>;
}

export default FormattedDate;
