// Navbar.js
import React, { useState, useEffect } from 'react';
import Link from 'next/link';
import { GiHamburgerMenu } from 'react-icons/gi';
import { IoClose, IoChevronDown } from 'react-icons/io5';
import LoadingSpinner from './LoadingSpinner';
import { useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation';
import setLanguage from 'next-translate/setLanguage';

const Navbar = () => {
    const { t, lang } = useTranslation('common');
    const [showMenu, setShowMenu] = useState(false);
    const [loading, setLoading] = useState(false);
    const router = useRouter();

    const toggleMenu = () => {
        setShowMenu(!showMenu);
    };

    const closeMenu = () => {
        setShowMenu(false);
    };

    // Show loader when route changes
    useEffect(() => {
        const handleRouteChange = () => {
            setLoading(false);
        };

        router.events.on('routeChangeComplete', handleRouteChange);
        router.events.on('routeChangeError', handleRouteChange);

        return () => {
            router.events.off('routeChangeComplete', handleRouteChange);
            router.events.off('routeChangeError', handleRouteChange);
        };
    }, [router.events]);

    const handleLinkClick = (isExternal = false) => {
        closeMenu();
        if (!isExternal) {
            setLoading(true); // Show loader only for internal links
        }
    };

    const handleLanguageChange = () => {
        const newLang = lang === 'en' ? 'da' : 'en';
        setLanguage(newLang);
        closeMenu();
    };

    return (
        <>
            {loading && <LoadingSpinner />}
            <header className="px-4 border-b-2 border-solid bg-neutral-100 relative transition-all duration-1000 text-[var(--primary)] z-50">
                <nav className="container m-auto">
                    <div className="flex justify-between items-center flex-wrap">
                        <div>
                            <Link
                                className="text-2xl font-bold"
                                href="/"
                                onClick={handleLinkClick}
                            >
                                ✈️
                            </Link>
                        </div>
                        <div
                            className="burger-menu py-4 lg:hidden text-2xl text-[var(--primary)]"
                            onClick={toggleMenu}
                        >
                            {showMenu ? <IoClose /> : <GiHamburgerMenu />}
                        </div>
                        <ul
                            className={`flex space-x-8 xl:space-x-8 pt-4 lg:pt-0 w-full lg:w-min whitespace-nowrap *:py-4 ${
                                showMenu
                                    ? 'flex-col lg:flex-row pb-4 pt-0 *:py-2'
                                    : 'hidden lg:flex'
                            }`}
                        >
                            {[
                                {
                                    href: '/',
                                    text: t('security_wait_times'),
                                    extraClasses: 'pl-8'
                                },
                                {
                                    href: '/departures',
                                    text: t('departures')
                                },
                                {
                                    href: '/arrivals',
                                    text: t('arrivals')
                                },
                                {
                                    href: '/passport-control',
                                    text: t('passport_control')
                                },
                                {
                                    href: '/parking',
                                    text: t('parking')
                                },
                                // {
                                //     href: '/compensation',
                                //     text: showMenu
                                //         ? t('late-flight-affiliate-header')
                                //         : t('late-flight-affiliate-link-text')
                                // },
                                {
                                    text: t('more'),
                                    submenus: [
                                        {
                                            href: '/blog',
                                            text: t('blog')
                                        },
                                        {
                                            href: '/statistics',
                                            text: t('statistics')
                                        },
                                        {
                                            href: '/about',
                                            text: t('about')
                                        },
                                        {
                                            href: 'https://www.cph.dk/490722/globalassets/3.-praktisk/oversigtskort/oversigtskort-kobenhavns-lufthavn-januar-2024.pdf',
                                            text: t('map'),
                                            isExternal: true // Add this flag for external links
                                        }
                                    ]
                                }
                            ].map(
                                (
                                    {
                                        href,
                                        text,
                                        extraClasses,
                                        submenus,
                                        isExternal
                                    },
                                    index
                                ) => {
                                    if (submenus) {
                                        return (
                                            <li key={index}>
                                                <div className="flex space-x-1 relative group flex-col">
                                                    <div className="flex items-center space-x-1">
                                                        <span>{text}</span>{' '}
                                                        <IoChevronDown
                                                            className={
                                                                showMenu
                                                                    ? 'hidden'
                                                                    : ''
                                                            }
                                                        />
                                                    </div>
                                                    <ul
                                                        className={`px-4 
                                                        ${
                                                            showMenu
                                                                ? ''
                                                                : 'hidden absolute top-full right-0 bg-neutral-100 shadow-lg group-hover:block border-2'
                                                        }`}
                                                    >
                                                        {submenus.map(
                                                            (
                                                                submenu,
                                                                index
                                                            ) => {
                                                                return (
                                                                    <li
                                                                        key={
                                                                            index
                                                                        }
                                                                        className={`p-4 bg-neutral-100 ${
                                                                            showMenu
                                                                                ? 'last:pb-0'
                                                                                : 'border-b-2 last:border-b-0'
                                                                        }`}
                                                                    >
                                                                        {submenu.isExternal ? (
                                                                            <a
                                                                                href={
                                                                                    submenu.href
                                                                                }
                                                                                className="hover:underline"
                                                                                onClick={() =>
                                                                                    handleLinkClick(
                                                                                        true
                                                                                    )
                                                                                }
                                                                                target="_blank"
                                                                                rel="noopener noreferrer"
                                                                            >
                                                                                {
                                                                                    submenu.text
                                                                                }
                                                                            </a>
                                                                        ) : (
                                                                            <Link
                                                                                href={
                                                                                    submenu.href
                                                                                }
                                                                                className="hover:underline"
                                                                                onClick={() =>
                                                                                    handleLinkClick()
                                                                                }
                                                                            >
                                                                                {
                                                                                    submenu.text
                                                                                }
                                                                            </Link>
                                                                        )}
                                                                    </li>
                                                                );
                                                            }
                                                        )}
                                                    </ul>
                                                </div>
                                            </li>
                                        );
                                    }
                                    return (
                                        <li key={index}>
                                            {href.includes('.pdf') ? (
                                                <a
                                                    href={href}
                                                    className={`hover:underline ${extraClasses}`}
                                                    onClick={handleLinkClick}
                                                    target="_blank" // Optional: Opens the PDF in a new tab
                                                    rel="noopener noreferrer" // Security for opening links in a new tab
                                                >
                                                    {text}
                                                </a>
                                            ) : (
                                                <Link
                                                    href={href}
                                                    className={`hover:underline ${extraClasses}`}
                                                    onClick={handleLinkClick}
                                                >
                                                    {text}
                                                </Link>
                                            )}
                                        </li>
                                    );
                                }
                            )}
                            <li className="ml-4 flex">
                                <button
                                    onClick={handleLanguageChange}
                                    className="flex"
                                >
                                    <div className="flex items-center mx-auto">
                                        <div
                                            className={`mr-2 ${
                                                showMenu ? '' : 'hidden'
                                            }`}
                                        >
                                            {t('change-language-to')}{' '}
                                        </div>
                                    </div>
                                    {lang === 'en' ? '🇩🇰' : '🇬🇧'}
                                </button>
                            </li>
                        </ul>
                    </div>
                </nav>
            </header>
        </>
    );
};

export default Navbar;
